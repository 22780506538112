import React, { SVGProps } from "react";

const AccountIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 20}
      height={props?.height || 20}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="transparent" d="M0 0H24V24H0z"></path>
      <path
        d="M6 8a6 6 0 1112 0A6 6 0 016 8zM5.43 16.902C7.057 16.223 9.224 16 12 16c2.771 0 4.935.22 6.559.898 1.742.727 2.812 1.963 3.382 3.76A1.03 1.03 0 0120.959 22H3.035c-.69 0-1.188-.67-.978-1.335.568-1.797 1.634-3.033 3.374-3.762z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default AccountIcon;
