import React, { SVGProps } from "react";

const TimeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
      data-testid="RestoreIcon"
      viewBox="0 0 24 24"
      width={props?.width || 24}
      height={props?.height || 24}
      {...props}
    >
      <path d="M13 3a9 9 0 00-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0013 21a9 9 0 000-18zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"></path>
    </svg>
  );
}

export default TimeIcon;
