import React, { SVGProps } from "react";

const InfoIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
      data-testid="InfoIcon"
      width={props?.width || 24}
      height={props?.height || 24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
    </svg>
  );
}

export default InfoIcon;
