import React, { SVGProps } from "react";

const HeThongIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 20}
      height={props?.height || 20}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"></path>
    </svg>
  );
}

export default HeThongIcon;
